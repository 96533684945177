/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ImageBackground from 'components/shared/ImageBackground'
import { Button, Hero3, HeadingLayout, Prides } from 'components/elements'

// Blog
import BlogContainer, { BlogConsumer, BlogButton } from 'components/shared/BlogContainer'
import BlogOverview from 'components/elements/BlogOverview'

// Data
import postInfo from 'src/graphql/postInfo'

const PartnerCardsContainer = styled.div`
  position: relative;
  margin-top: -100px;
  z-index: 2;

  @media screen and (max-width: 991px) {
    margin-top: -250px;
    padding: 0 100px 0 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 40px 0 40px;
  }

  @media screen and (max-width: 470px) {
    margin-top: -300px;
    padding: 0 10px 0 10px;
  }
`
const ParnterCard = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 40px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  margin: 10px;
  display: flex;
  background: white;
  img {
    margin: auto;
  }
  @media screen and (max-width: 992px) {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    margin: 5px;
    padding: 10px;
    img {
      width: 100%;
    }
  }
`
const PartnerImage = styled(ImageBackground)`
  max-width: 60%;
  max-height: 60%;
  min-width: 60%;
  min-height: 60%;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const blogFields = postInfo.get('blog')

  return (
    <Layout activeHeader="Partners">
      <SEO yoast={yoast} path={path} />

      <Hero3
        fields={acf.banner}
        bigBottom
      />
      
      <PartnerCardsContainer className="container d-flex flex-wrap justify-content-center">
        {acf.partners.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <ParnterCard key={`key${index}`}>
              {item.link.url && (
                <a href={item.link.url} target="_blank" rel="noreferrer" className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <PartnerImage style={{ backgroundSize: 'contain' }} src={item.image} />
                </a>
              )}
              {!item.link.url && (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <PartnerImage style={{ backgroundSize: 'contain' }} src={item.image} />
                </div>
              )}
            </ParnterCard>
          )
        })}
      </PartnerCardsContainer>

      <div className="pt-lg-5 mt-5">
        <div className="pt-5">
          <Prides
            fields={acf.blok_1}
          />
        </div>
      </div>
      
      <div className="pb-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={'Partner <br/> &nbsp;&nbsp; Nieuws'}>
            <div className="container">
              <BlogContainer
                postType='blog'
                settings={{
                  limit: 6,
                  postIds: acf.blog.articles
                }}
              >
                <BlogConsumer>
                  {({ posts, showMoreButton, showLessButton }) => {
                    return (
                      <>
                        <BlogOverview posts={posts} ids={acf.blog.articles} />

                        <div className="text-center mt-5">
                          {showMoreButton && (
                            <Button className="mx-3">
                              <BlogButton>
                                {blogFields.acf.meta.show_more_button}
                              </BlogButton>
                            </Button>
                          )}
                          {showLessButton && (
                            <Button className="mx-3">
                              <BlogButton increment={false}>
                                {blogFields.acf.meta.show_less_button}
                              </BlogButton>
                            </Button>
                          )}
                        </div>
                      </>
                    )
                  }}
                </BlogConsumer>
              </BlogContainer>
            </div>
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
        }

        partners {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 150) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          link {
            url
            title
            target
          }
        }

        blok_1 {
          description
          items {
            description
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }

        blog {
          background_title
          articles
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate